import { userAtom } from "@/atoms/userAtom"
import { DraftOrder, OptimizationRouteSummary } from "@/types/draftOrder.types"
import { getHumanizedTime } from "@/utils/datetime"
import { humanizeDistance } from "@/utils/distance"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"

interface OptimizationRouteDetailsProps {
  route: OptimizationRouteSummary & DraftOrder
  index: number
  driverName?: string
}

const OptimizationRouteDetails = ({ route, index, driverName }: OptimizationRouteDetailsProps) => {
  const { t } = useTranslation()
  const { preference } = useRecoilValue(userAtom)

  return (
    <>
      <div className="flex flex-row justify-between" key={`optimization-summary-${route.id}`}>
        <div>
          {driverName ? driverName : `${t("common.route")} ${index + 1}`}
          {" | "}
          <span>{`${route?.recipients?.length} ${t("common.stops")}`}</span>
        </div>
        <div>
          <div className="flex justify-end">
            {route.distance && humanizeDistance(route.distance, preference.distanceUnit)}
          </div>
          <div className="flex justify-end">
            {route.duration && getHumanizedTime(route.duration)}
          </div>
        </div>
      </div>
    </>
  )
}

export default OptimizationRouteDetails
