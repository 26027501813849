import React, { useRef } from "react"
import { ANTD_PICKER_DATE_FORMAT, LUXON_PICKER_DATE_FORMAT } from "@/utils/datetime"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import LuxonDatePicker from "@/modules/shared/LuxonDatePicker"

type DateFilterProps = {
  date?: string // formatted as LUXON_PICKER_DATE_FORMAT
  setDate: (formattedDate: string) => void
}

export default function DateFilter({ date, setDate }: DateFilterProps) {
  const datePickerRef = useRef<any>(null)
  const { t } = useTranslation()
  const selectedDate = DateTime.fromFormat(date || "", LUXON_PICKER_DATE_FORMAT)

  const onSelectDate = (_: DateTime | null, dateString: string | string[]) => {
    if (!date) return

    if (datePickerRef.current) {
      datePickerRef.current.blur()
    }

    setDate(typeof dateString === "string" ? dateString : dateString?.[0]!)
  }

  return (
    <LuxonDatePicker
      ref={datePickerRef}
      placeholder={t("common.date")}
      allowClear={false}
      format={ANTD_PICKER_DATE_FORMAT}
      value={selectedDate}
      onChange={onSelectDate}
    />
  )
}
