import { chatIsOpenAtom } from "@/atoms/chatAtom"
import { userAtom } from "@/atoms/userAtom"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faMessage } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useRecoilValue, useSetRecoilState } from "recoil"

interface ContactDriverButtonProps {
  phone: string
  driverID?: string
}

export const ContactDriverButton: React.FC<ContactDriverButtonProps> = ({ phone, driverID }) => {
  const user = useRecoilValue(userAtom)
  const setChatIsOpen = useSetRecoilState(chatIsOpenAtom)
  return (
    <>
      {window && window?.CometChat && user?.org?.cometChatEnabled ? (
        <div>
          <FontAwesomeIcon
            className="text-slate-700 cursor-pointer text-sm"
            icon={faMessage}
            onClick={() => setChatIsOpen({ isOpen: true, guid: `${user.org?.id}-${driverID}` })}
          />
        </div>
      ) : (
        <a href={`https://wa.me/${phone}`} rel="noreferrer" target="_blank">
          <FontAwesomeIcon className="text-black" icon={faWhatsapp} />
        </a>
      )}
    </>
  )
}
