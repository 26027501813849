import { IFiltersState } from "@/hooks/useOrdersFilters"
import axios from "@/modules/shared/AxiosInterceptor"
import {
  DraftOrderOptimizationResult,
  DraftOrder,
  PatchDraftFleetOrdersRequest,
  PatchDraftOrdersRequest,
  DraftOrderTableStatusFilter,
} from "@/types/draftOrder.types"
import { StopDescription } from "@/types/orders.types"
import { Location } from "@/types/orders.types"
import { IQuote, IQuotePayload } from "@/types/quotes.types"
import { getDateTimeMillis } from "@/utils/datetime"
import { AxiosResponse } from "axios"
import { isEmpty } from "lodash"

interface GetDraftOrdersQueryParams extends IFiltersState {
  to?: number
  from?: number
  q?: string
}

interface GetDraftOrdersResponse {
  draftOrders: DraftOrder[]
  metadata: any
}

export interface DraftOrderUpdatePayload {
  draftOrdersIds: string[]
  params?: IFiltersState
}

const buildQueryParams = (params: GetDraftOrdersQueryParams | undefined): string => {
  if (!params) {
    return ""
  }
  const fromTimestamp = getDateTimeMillis(params?.dates?.range?.[0] || "", params?.dates?.format)
  const toTimestamp = getDateTimeMillis(
    params?.dates?.range?.[1] || "",
    params?.dates?.format,
    true
  )

  const {
    limit,
    offset,
    draftStatus: draftStatusParam,
    zoneIds: zoneIdsParams,
    driversIds: driversIdsParams,
    sources: sourcesParams,
    locationIds: locationIdsParams,
    dateFilterBy,
  } = params
  const limitParam = limit !== undefined ? `&limit=${limit}` : ""
  const offsetParam = offset !== undefined ? `&offset=${offset}` : ""
  const to = toTimestamp !== undefined ? `&to=${toTimestamp}` : ""
  const from = fromTimestamp !== undefined ? `&from=${fromTimestamp}` : ""
  const q = params.q !== undefined && params.q ? `&q=${encodeURIComponent(params.q)}` : ""
  const status =
    draftStatusParam !== undefined &&
    !isEmpty(draftStatusParam) &&
    draftStatusParam !== DraftOrderTableStatusFilter.TOTAL
      ? `&status=${draftStatusParam}`
      : ""
  const zoneIds = zoneIdsParams !== undefined ? `&zoneIds=${zoneIdsParams}` : ""
  const driversIds = driversIdsParams !== undefined ? `&driversIds=${driversIdsParams}` : ""
  const sources = sourcesParams !== undefined ? `&sources=${sourcesParams}` : ""
  const locations = locationIdsParams !== undefined ? `&locations=${locationIdsParams}` : ""
  const dateFilterByParam = dateFilterBy !== undefined ? `&dateFilterBy=${dateFilterBy}` : ""

  return `?${limitParam}${offsetParam}${to}${from}${q}${status}${driversIds}${zoneIds}${sources}${locations}${dateFilterByParam}`
}

export async function createDraftOrdersDeliveries(
  payload: DraftOrderUpdatePayload,
  blastAssignmentLimit?: Record<number, number>
): Promise<AxiosResponse<DraftOrder[], any>> {
  try {
    const queryString = buildQueryParams(payload?.params)
    return axios.post(`/draft-order/deliveries${queryString}`, {
      ...(payload?.params?.all ? { itemIds: [], all: true } : { itemIds: payload?.draftOrdersIds }),
      blastAssignmentLimit,
    })
  } catch (error) {
    throw error
  }
}

export async function getDraftOrders(
  params: IFiltersState,
  signal?: AbortSignal
): Promise<AxiosResponse<GetDraftOrdersResponse, any>> {
  try {
    const queryString = buildQueryParams(params)
    return axios.get(`/draft-order/v2${queryString}`, { signal })
  } catch (error) {
    console.log("cancelled")
    throw error
  }
}

export async function getSpecificDraftOrders(
  itemIds: string[]
): Promise<AxiosResponse<DraftOrder[], any>> {
  try {
    return axios.post(`/draft-order/items`, {
      itemIds,
    })
  } catch (error) {
    throw error
  }
}

export async function getSingleDraftOrder(
  draftOrderId: string
): Promise<AxiosResponse<DraftOrder, any>> {
  try {
    return axios.get(`/draft-order/${draftOrderId}`)
  } catch (error) {
    throw error
  }
}

export async function deleteDraftOrders(payload: DraftOrderUpdatePayload) {
  try {
    const queryString = buildQueryParams(payload.params)
    return axios.delete(`/draft-order/?${queryString}`, {
      data: {
        ...(payload?.params?.all
          ? { itemIds: [], all: true }
          : { itemIds: payload?.draftOrdersIds }),
      },
    })
  } catch (error) {
    throw error
  }
}

export async function postDraftOrders(
  orders: IQuotePayload[],
  duplicated?: boolean,
  duplicateMode?: string, // "" | "all" | "only"
  createdForTest?: boolean
): Promise<AxiosResponse<DraftOrder[], any>> {
  return axios
    .post("/draft-order/", {
      orders,
      duplicated,
      duplicateMode,
      createdForTest,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function createDraftOrdersQuotes(itemIds: string[], ruleId: string = "") {
  return axios
    .post(`/draft-order/quotes`, {
      itemIds,
      ruleId: ruleId,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function createDraftOrderQuotes(
  itemId: string
): Promise<AxiosResponse<DraftOrder & { quotes: IQuote[] }, any>> {
  return axios
    .post(`/draft-order/${itemId}/quotes/async`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getAsyncDraftOrderQuotes(
  itemId: string
): Promise<
  AxiosResponse<{ quoteBatchId: string; quotes: IQuote[]; status: "fetching" | "done" }, any>
> {
  return axios
    .get(`/draft-order/${itemId}/quotes`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function patchDraftOrderQuoteId(
  itemId: string,
  quoteId: string | null | undefined
): Promise<AxiosResponse<DraftOrder, any>> {
  try {
    return axios.patch(`/draft-order/${itemId}/quote`, {
      quoteId,
    })
  } catch (error) {
    throw error
  }
}

export async function patchDraftOrderRecipients(
  itemId: string,
  fields: {
    recipients: Location[] | null
    autoSelectQuote: boolean
    driverId?: string
    teamIDs?: string[] | number[]
    optimize?: boolean
  }
): Promise<AxiosResponse<DraftOrder, any>> {
  const { recipients, autoSelectQuote = false, driverId, optimize } = fields
  try {
    return axios.patch(`/draft-order/${itemId}`, {
      ...(recipients ? { recipients } : {}),
      ...(autoSelectQuote ? { autoSelectQuote } : {}),
      ...(driverId ? { driverId } : {}),
      ...(optimize ? { optimize } : {}),
    })
  } catch (error) {
    throw error
  }
}

export async function patchDraftOrders(
  payload: DraftOrderUpdatePayload,
  fields: PatchDraftOrdersRequest
): Promise<AxiosResponse<DraftOrder[], any>> {
  const queryString = buildQueryParams(payload?.params)
  const { storeId, deliveryType, vehicleType, schedule, pickup, ruleId, arrivalETA, arrival } =
    fields
  try {
    return axios.patch(`/draft-order/${queryString}`, {
      ...(payload?.params?.all ? { itemIds: [], all: true } : { itemIds: payload?.draftOrdersIds }),
      ...(storeId !== undefined ? { storeId } : {}),
      ...(pickup ? { pickup } : {}),
      ...(deliveryType ? { deliveryType } : {}),
      ...(vehicleType || vehicleType === "" ? { vehicleType } : {}),
      ...(schedule || schedule === null ? { schedule } : {}),
      ...(ruleId || ruleId === "" ? { ruleId } : {}),
      ...(arrivalETA || arrivalETA === 0 ? { arrivalETA } : {}),
      ...(arrival !== undefined ? { arrival } : {}),
    })
  } catch (error) {
    throw error
  }
}

export async function patchDraftOrdersFleet(
  payload: DraftOrderUpdatePayload,
  fields: PatchDraftFleetOrdersRequest
): Promise<AxiosResponse<DraftOrder[], any>> {
  const { driverId, teamId, assignmentType } = fields
  const queryString = buildQueryParams(payload?.params)
  try {
    return axios.patch(`/draft-order/${queryString}`, {
      ...(payload?.params?.all ? { itemIds: [], all: true } : { itemIds: payload?.draftOrdersIds }),
      ...(driverId !== undefined ? { driverId } : {}),
      ...(teamId && teamId !== 0 ? { teamId } : { teamId: null }),
      ...(assignmentType !== undefined ? { assignmentType } : {}),
    })
  } catch (error) {
    throw error
  }
}

export async function mergeDraftOrders(itemIds: string[]): Promise<AxiosResponse<DraftOrder, any>> {
  try {
    return axios.post(`/draft-order/merge`, {
      itemIds,
    })
  } catch (error) {
    throw error
  }
}

export async function extractDropoffFromDraftOrder(
  draftOrderId: string,
  dropoffIndex: number,
  address: string
): Promise<AxiosResponse<DraftOrder[], any>> {
  try {
    return axios.post(`/draft-order/${draftOrderId}/extract-dropoff`, {
      dropoffIndex,
      address,
    })
  } catch (error) {
    throw error
  }
}

export async function extractDropoffsFromDraftOrder(
  draftOrderId: string
): Promise<AxiosResponse<null, any>> {
  try {
    return axios.post(`/draft-order/${draftOrderId}/extract-dropoffs`)
  } catch (error) {
    throw error
  }
}

export async function optimizeDraftOrders(
  payload: DraftOrderUpdatePayload,
  drivers: string[],
  startLocationId?: string
): Promise<AxiosResponse<DraftOrderOptimizationResult, any>> {
  try {
    const queryString = buildQueryParams(payload?.params)
    return axios.post(`/draft-order/optimize${queryString}`, {
      ...(payload?.params?.all ? { itemIds: [], all: true } : { itemIds: payload?.draftOrdersIds }),
      drivers,
      startLocationId,
    })
  } catch (error) {
    throw error
  }
}

export async function putDraftBarcodes(
  orderIds: string[]
): Promise<AxiosResponse<DraftOrder[], any>> {
  try {
    return axios.put(`/draft-order/barcodes`, {
      deliveryIds: orderIds,
    })
  } catch (error) {
    throw error
  }
}

export const putDraftOrderRoute = async (
  draftOrderId: string,
  routeDirections: string
): Promise<AxiosResponse<string>> => {
  try {
    return axios.put(`draft-order/${draftOrderId}/route`, { routeDirections })
  } catch (error) {
    throw error
  }
}

export const postMoveDraftStop = async (
  targetOrderId: string,
  stop: StopDescription
): Promise<AxiosResponse<string>> => {
  try {
    return axios.post(`draft-order/move-stop`, { targetOrderId, stop })
  } catch (error) {
    throw error
  }
}
