import { notification } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation, faCircleCheck } from "@fortawesome/free-solid-svg-icons"

import { ReactNode } from "react"
import { NotificationPlacement } from "antd/es/notification/interface"

const ErrorNotification = (
  message: string,
  desc: string | ReactNode,
  placement?: NotificationPlacement
) => {
  notification.open({
    message: message,
    duration: 5,
    description: desc,
    placement: placement,
    icon: (
      <FontAwesomeIcon icon={faCircleExclamation} style={{ color: "#A91B0D", height: "1.2em" }} />
    ),
  })
}

const SuccessNotification = (message: string, desc: string, placement?: NotificationPlacement) => {
  notification.open({
    message: message,
    description: desc,
    placement: placement,
    icon: <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#41c219", height: "1.2em" }} />,
  })
}

export { ErrorNotification, SuccessNotification }
