import React, { useEffect, useState } from "react"
import { appWithI18Next } from "ni18n"
import { ni18nConfig } from "../../ni18n.config"
import flagsmith from "flagsmith"
import { FlagsmithProvider } from "flagsmith/react"
import { RecoilRoot } from "recoil"
import RecoilNexus from "recoil-nexus"

// import "antd/dist/antd.less"
import "../styles/global.css"
import "../styles/auth-lock.css"
import "../styles/login.css"
import "../styles/sandbox.css"
import "../styles/order-history.css"
import "../styles/request-rider.css"
import "../styles/todays-orders.css"
import "../styles/profile.css"
import "../styles/payment.css"
import "../styles/team-profile.css"
import "../styles/kosmo-button.css"
import "../styles/override-fontawesome.css"

import { Auth0Provider } from "@auth0/auth0-react"
import Localization from "@/modules/shared/Localization/Localization.container"
import { SandboxProvider } from "@/layout/Nav/SandboxSwitcher"
import { AxiosInterceptor } from "@/modules/shared/AxiosInterceptor"
import { useLoadScript } from "@react-google-maps/api"
import { Settings } from "luxon"
import Poller from "@/modules/shared/Poller/Poller"
import { useTranslation } from "react-i18next"
import { useRouter } from "next/router"
import { AppProps } from "next/app"
import dynamic from "next/dynamic"

import { MapProvider } from "@/modules/shared/Map/MapProvider"
import ThemeProvider from "@/modules/shared/ThemeProvider/ThemeProvider"
import RouteOptimizationModal from "@/modules/shared/RouteOptimizationModal/RouteOptimizationModal"

const googleLibraries = ["places", "geometry"] as ["places", "geometry"]

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const [root, setRoot] = useState<HTMLElement | null>(null)
  const { loadIntercom } = require("next-intercom")

  if (process.env.NEXT_PUBLIC_INTERCOM_APPID) {
    loadIntercom({
      appId: process.env.NEXT_PUBLIC_INTERCOM_APPID, // default : ''
      ssr: false, // default: false
      initWindow: false, // default: true
      delay: 0, // default: 0  - usefull for mobile devices to prevent blocking the main thread
    })
  }

  const HereLibrary = dynamic(() => import("@/modules/map/Here/Here"), { ssr: false })
  const InjectBrandingNoSSR = dynamic(
    () => import("@/modules/shared/ThemeProvider/InjectBranding"),
    {
      ssr: false,
    }
  )

  useEffect(() => {
    if (typeof window.document !== "undefined") {
      setRoot(document.getElementById("__next"))
    }
  }, [root])

  // TODO: Pass info to the map
  const {} = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
    libraries: googleLibraries,
  })

  const CometChat = dynamic(() => import("../modules/chat/Chat"), {
    ssr: false,
  })
  // Set language from local storage or default to english, can be changed in the language switcher
  useEffect(() => {
    const locale =
      typeof window !== "undefined" && window.localStorage?.getItem("language_preference")
    const browserLang =
      typeof navigator !== "undefined" ? navigator.language?.split("-")?.[0] || "" : ""
    const defaultLocale =
      router?.locales?.includes(browserLang) && !locale ? browserLang : locale || "en"

    if (typeof window !== "undefined") {
      window.localStorage?.setItem("language_preference", defaultLocale || "en")
      Settings.defaultLocale = defaultLocale
    }

    i18n.changeLanguage(defaultLocale)
  }, [])

  return (
    <SandboxProvider>
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN || ""}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || ""}
        authorizationParams={{
          // redirect_uri: `${process.env.NEXT_PUBLIC_APP_HOST}/callback`,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
          scope: "openid profile email offline_access",
        }}
      >
        <FlagsmithProvider
          options={{
            environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_ENVIRONMENTID || "",
            cacheFlags: true,
            cacheOptions: {
              ttl: 60 * 1000,
              skipAPI: true,
            },
          }}
          flagsmith={flagsmith}
        >
          {/* @ts-ignore: TODO require recoil package update otherwise */}
          <RecoilRoot>
            <RecoilNexus />
            {/* Check whether it's the best place to have before merging */}
            <MapProvider>
              <Poller />
              <Localization />
              <HereLibrary />
              <AxiosInterceptor>
                <ThemeProvider>
                  <>
                    <InjectBrandingNoSSR />
                    {!!process.env.NEXT_PUBLIC_COMETCHAT_APPID && <CometChat />}
                    <RouteOptimizationModal />
                    <Component {...pageProps} />
                  </>
                </ThemeProvider>
              </AxiosInterceptor>
            </MapProvider>
          </RecoilRoot>
        </FlagsmithProvider>
      </Auth0Provider>
    </SandboxProvider>
  )
}

export default appWithI18Next(App, ni18nConfig)
