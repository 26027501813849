import { Dispatch, SetStateAction, useEffect, useState } from "react"
import removeAccents from "remove-accents"
import {
  DeliveryExcelTypes,
  ISheetRow,
  priorityKeys,
} from "@/modules/shared/UploadTemplate/upload.types"
import { v4 as uuidv4 } from "uuid"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { IQuotePayload } from "@/types/quotes.types"
import { UploadFile } from "antd"
import { useUser } from "./useUser"
import {
  isEmptyCell,
  getAddressDetail,
  formatUploadedParcelDetails,
  getDeliveryTypeAndScheduleFromTemplate,
  formatUploadedRecipientsFromTemplate,
  getReverseAddress,
  getCleanUploadedRecipient,
} from "@/modules/shared/UploadTemplate/upload_utils"
import { DRAFT_TEMPLATE_VERSIONS } from "@/modules/shared/DropoffForm/shared/draftTemplateVersions"
import { VehicleType } from "@/types/vehicle.types"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { EU_DATE_FORMAT_UPLOAD, EU_DATE_TIME_FORMAT_UPLOAD } from "@/utils/datetime"
import { getColumnDescription } from "@/utils/excel"
import { splitFullName } from "@/utils/formatFullName"
import { CountryCode } from "libphonenumber-js"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"
import { read, utils } from "xlsx"
import { useTeams } from "./useTeams"
import { isCodAvailableCountryAtom, locationsSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import { useTranslation } from "react-i18next"
import { PickupDetailsType } from "@/types/createSingleOrder.types"
import { formatSenderRecipientPayload } from "@/api/quotes"
import { localizationAtom } from "@/atoms/localizationAtom"
import { DestinationPriority, RecipientMetadata, StopType } from "@/types/orders.types"
import { useGeocoding } from "./useGeocoding"
import { useDraftOrders } from "./useDraftOrders"
import { DraftOrder } from "@/types/draftOrder.types"
import { useClients } from "./useClients"

interface IUseUploadTeamplate {
  file: UploadFile[]
  setFile: any
  processTemplateFile: (
    uploadCallback: (orders: DraftOrder[] | IQuotePayload[]) => Promise<any>
  ) => Promise<IQuotePayload[] | undefined>
  progressPercent: number
  isLoading: boolean
  isDisabled: boolean
  setSelectedPickup: Dispatch<SetStateAction<PickupDetailsType | undefined>>
  selectedPickupId: string | undefined
}
export type AddressDetails = {
  postalCode: string
  lat: number
  lng: number
  isWarning?: boolean
}

const isUndefined = (value?: string) => {
  return !value || value === "undefined"
}

export const useUploadTemplate = (isPublic: boolean): IUseUploadTeamplate => {
  const { t } = useTranslation()

  const { user } = useUser()
  const { enrichedClients, fetchClients } = useClients()
  const { geocodeByAddress } = useGeocoding()
  const { createDraftOrders } = useDraftOrders()
  const locations = useRecoilValue(locationsSelector)

  const isCodAvailableCountry = useRecoilValue(isCodAvailableCountryAtom)
  const { locale } = useRecoilValue(localizationAtom)

  const [file, setFile] = useState<UploadFile[]>([])
  const [selectedPickup, setSelectedPickup] = useState<PickupDetailsType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [progressPercent, setProgressPercent] = useState<number>(0)

  const referenceCountry = user?.org?.country || locale

  const MAX_UPLOAD_DRAFT_LIMIT = 5000

  const { findDriverByName, findTeamByName, findDriverById } = useTeams()

  const addressCache: Record<string, AddressDetails> = {}
  const coordsCache: Record<string, string> = {}

  useEffect(() => {
    setIsDisabled(!file)
  }, [file])

  useEffect(() => {
    if (file) {
      setIsDisabled(isLoading)
    }
  }, [isLoading])

  const convertSheetDataToQuotePayload = async (order: ISheetRow) => {
    try {
      let {
        recipientName,
        recipientAddress,
        recipientPostalCode,
        buildingNumber,
        recipientCountry,
        recipientLatitude,
        recipientLongitude,
        recipientPhone,
        recipientEmail,
        dropOffNote,
        stopType,

        senderName,
        senderAddress,
        senderPostalCode,
        senderBuildingNumber,
        senderLatitude,
        senderLongitude,
        senderPhone,
        senderEmail,
        senderNote,

        cashOnDeliveryAmount,
        deliveryType,
        orderReferenceId,
        barcode,
        // productType,
        orderNumber,

        teamName,
        driverName,

        parcelValue,
        parcelWeight,
        parcelLength,
        parcelHeight,
        parcelWidth,
        parcelQuantity,
        parcelDescription,
        parcelDimensions,
        parcelDimensionsV2,

        date,
        scheduledTime,
        arrivalTimeFrom,
        arrivalTimeTo,
        timeAtStop,
        vehicleType,
        priority,
        clientId,
        endLocation,
      } = order
      const orderNo = orderNumber || uuidv4()

      const quote: IQuotePayload = {
        recipients: [],
        deliveryType: ServerDeliveryType.EMPTY,
        ...(!isEmptyCell(vehicleType)
          ? { vehicles: [vehicleType?.toUpperCase()] as VehicleType[] }
          : {}),
        //@ts-ignore
        orderNumber: `${orderNo}`,
      }

      // Pickup handling
      if (selectedPickup) {
        quote.sender = formatSenderRecipientPayload(selectedPickup)
      } else if (senderName || senderAddress || senderBuildingNumber || senderPhone || senderNote) {
        const formattedSenderAddress = `${senderAddress}${
          senderPostalCode ? " " + senderPostalCode : ""
        }`

        let senderAddressDetails = addressCache[senderAddress]
        if (!senderAddressDetails) {
          if (!senderLatitude && !senderLongitude) {
            senderAddressDetails = await getAddressDetail(
              geocodeByAddress,
              formattedSenderAddress,
              (recipientCountry as CountryCode) || referenceCountry,
              undefined,
              `${recipientPostalCode}`
            )
          } else {
            senderAddressDetails = {
              lat: Number(senderLatitude),
              lng: Number(senderLongitude),
              postalCode: `${senderPostalCode}`,
              isWarning: false,
            }
          }
          addressCache[senderAddress] = senderAddressDetails
        }

        const { postalCode, lat, lng, isWarning } = senderAddressDetails
        const { firstName, lastName } = splitFullName(`${senderName}`)

        quote.sender = {
          firstName: firstName ? `${firstName}` : "",
          lastName: lastName ? `${lastName}` : "",
          email: senderEmail ? `${senderEmail}` : "",
          location: {
            alias: senderName ? `${senderName}` : "",
            buildingNumber: senderBuildingNumber ? `${senderBuildingNumber}` : "",
            address: senderAddress ? `${senderAddress}` : "",
            postalCode: postalCode ? `${postalCode}` : "",
            latitude: Number(lat) || 0,
            longitude: Number(lng) || 0,
            isWarning: isWarning,
          },
          phone: senderPhone ? formatPhoneNumber(String(senderPhone), referenceCountry) : "",
          notes: senderNote ? `${senderNote}` : "",
          type: StopType.PICKUP,
        }
      }

      const client = clientId
        ? enrichedClients?.find((client) => client.clientId === clientId)
        : undefined

      // Recipient
      const cleanRecipient = getCleanUploadedRecipient(
        {
          name: isUndefined(recipientName) ? "" : `${recipientName}`,
          address: isUndefined(recipientAddress) ? "" : `${recipientAddress}` || "",
          phone: isUndefined(recipientPhone) ? "" : `${recipientPhone}` || "",
          buildingNumber: isUndefined(buildingNumber) ? "" : `${buildingNumber}`,
          dropOffNote: isUndefined(dropOffNote) ? "" : `${dropOffNote}`,
          country: recipientCountry,
          email: isUndefined(recipientEmail) ? "" : `${recipientEmail}`,
          latitude: Number(recipientLatitude),
          longitude: Number(recipientLongitude),
        },
        client
      )
      if (cleanRecipient) {
        let recipientGeocodedPickup = {} as AddressDetails | undefined

        if (!cleanRecipient?.latitude && !cleanRecipient?.longitude) {
          recipientGeocodedPickup = addressCache[cleanRecipient?.address]
          if (!recipientGeocodedPickup && !!recipientAddress) {
            recipientGeocodedPickup = await getAddressDetail(
              geocodeByAddress,
              cleanRecipient?.address,
              cleanRecipient?.country || referenceCountry,
              selectedPickup
                ? new google.maps.LatLng(selectedPickup?.latitude!, selectedPickup?.longitude!)
                : undefined,
              `${cleanRecipient?.postalCode}`
            )

            addressCache[cleanRecipient?.address] = recipientGeocodedPickup
          }
        } else {
          if (!cleanRecipient?.address) {
            let cacheAddress =
              coordsCache[`${cleanRecipient?.latitude},${cleanRecipient?.longitude}`]
            if (!cacheAddress) {
              cacheAddress = await getReverseAddress(
                cleanRecipient?.latitude,
                cleanRecipient?.longitude
              )
              if (cacheAddress)
                coordsCache[`${cleanRecipient?.latitude},${cleanRecipient?.longitude}`] =
                  cacheAddress
            }
            cleanRecipient.address = cacheAddress || ""
          }
          recipientGeocodedPickup = {
            lat: cleanRecipient?.latitude,
            lng: cleanRecipient?.longitude,
            postalCode: `${cleanRecipient?.postalCode}`,
          }
        }

        const formattedPriority = !!priority ? removeAccents(priority?.toLowerCase()) : ""
        const priorityKey = priorityKeys[formattedPriority] || DestinationPriority.DEFAULT

        quote.recipients = [
          {
            expectedTimeAtStop: timeAtStop ? parseInt(timeAtStop) * 60 : 0,
            cashOnDeliveryAmount:
              isCodAvailableCountry && cashOnDeliveryAmount && Number(cashOnDeliveryAmount)
                ? Number(cashOnDeliveryAmount)
                : undefined,
            ...formatUploadedRecipientsFromTemplate(
              cleanRecipient?.address && recipientGeocodedPickup
                ? recipientGeocodedPickup
                : {
                    lat: 0,
                    lng: 0,
                    postalCode: "",
                  },
              cleanRecipient?.address ?? "",
              cleanRecipient?.name,
              cleanRecipient?.email,
              cleanRecipient?.buildingNumber,
              cleanRecipient?.phone,
              cleanRecipient?.dropOffNote,
              orderReferenceId,
              (recipientCountry as CountryCode) || referenceCountry,
              recipientGeocodedPickup?.isWarning
            ),
            type:
              !isEmpty(stopType) && stopType?.includes(StopType.PICKUP)
                ? StopType.PICKUP
                : StopType.DROPOFF,
            barcodes: barcode
              ? [
                  {
                    id: barcode ? `${barcode}` : "",
                    createdAt: DateTime.now().toMillis(),
                  },
                ]
              : [],
            priority: priorityKey,
            ...(client
              ? {
                  metadata: {
                    clientId,
                  } as RecipientMetadata,
                }
              : {}),
          },
        ]

        if (quote.recipients && quote?.recipients?.[0]) {
          const parsedParcels = formatUploadedParcelDetails(
            parcelValue,
            parcelDimensions,
            parcelDimensionsV2,
            parcelWeight,
            parcelLength,
            parcelHeight,
            parcelWidth,
            parcelQuantity,
            parcelDescription
          )
          quote.recipients[0].parcel = !isEmpty(parsedParcels) ? parsedParcels : {}
        }
      }

      // Check end location
      // Search for the end location in the store locations list
      // Search by id or by alias
      const end = locations.find(
        (location) => location.id === endLocation || location.alias === endLocation
      )
      if (end && endLocation) {
        quote.arrival = {
          contact: {
            name: end.alias || end.address,
            phone: end.phone,
            email: end.email || "",
            notes: end.notes || "",
          },
          location: {
            address: end.address,
            latitude: end.latitude,
            longitude: end.longitude,
          },
          plannedArrivalAt: 0,
          finishedAt: 0,
        }
      }

      // Team and Driver
      if (!isPublic) {
        if (teamName) {
          const team = findTeamByName(typeof teamName === "number" ? `${teamName}` : teamName)
          if (team) {
            quote.teamId = team.id
          }
        }

        if (driverName) {
          const driver =
            findDriverByName(typeof driverName === "number" ? `${driverName}` : driverName) ||
            findDriverById(typeof driverName === "number" ? `${driverName}` : driverName)
          if (driver) {
            quote.driverId = driver.id
            if (!driver.teamIDs?.includes(quote?.teamId || 0)) {
              quote.teamId = undefined
            }
            if (!isEmpty(driver?.teamIDs) && !quote.teamId) {
              quote.teamId = driver?.teamIDs?.[0]
            }
          }
        }
      }

      // Delivery Type and schedule
      const { quoteDeliveryType, schedule } = getDeliveryTypeAndScheduleFromTemplate(
        deliveryType,
        date,
        scheduledTime
      )

      if (quoteDeliveryType !== ServerDeliveryType.EMPTY) {
        quote.deliveryType = quoteDeliveryType
      }

      if (schedule) {
        quote.schedule = schedule
      }

      // DropoffBy
      const dropoffByDate =
        !!date && date !== DeliveryExcelTypes.ASAP
          ? date
          : DateTime.now().toFormat(EU_DATE_FORMAT_UPLOAD)

      if (
        dropoffByDate &&
        (!isEmptyCell(arrivalTimeFrom) || !isEmpty(arrivalTimeTo)) &&
        quote.recipients
      ) {
        const arrivalTimeFromDT = DateTime.fromFormat(
          dropoffByDate + " " + arrivalTimeFrom,
          EU_DATE_TIME_FORMAT_UPLOAD
        )
        const arrivalTimeToDT = DateTime.fromFormat(
          dropoffByDate + " " + arrivalTimeTo,
          EU_DATE_TIME_FORMAT_UPLOAD
        )
        quote.recipients![0]!.expectedArrival = {
          from: arrivalTimeFromDT.toMillis(),
          to: arrivalTimeToDT.toMillis(),
        }
      }
      return quote
    } catch (err: any) {
      console.log(err)
      throw new Error(t("errors.draftUpload.convertTemplateError"), err)
      // handleFileError(t("errors.draftUpload.convertTemplateError"), err)
      // return
    }
  }
  const convertFiletoJson: () => Promise<any[] | undefined> = async () => {
    try {
      if (!file.length || !file?.[0]) {
        throw new Error("No file selected")
      }

      const data = await file[0].originFileObj?.arrayBuffer()
      const workbook = read(data, {
        cellStyles: true,
      })
      // Take sheet 0 or sheet with name "Kosmo Template"
      const sheet = workbook.Sheets[workbook.SheetNames[0] || "Kosmo Template"]
      if (!sheet) {
        throw new Error(t("errors.draftUpload.noTemplateFound"))
      }
      //format sheet to json
      var range = utils.decode_range(sheet!["!ref"]!)

      range.s.r = 1 // <-- zero-indexed, so setting to 2 will skip row 0,1

      sheet!["!ref"] = utils.encode_range(range)

      // Get the version sheet
      // If the version sheet is not found, we will use the second sheet
      const sheetName = workbook.Sheets["DoNotTouch"] ? "DoNotTouch" : workbook.SheetNames?.[1]
      const versionSheet =
        sheetName && workbook.Sheets[sheetName] && !isEmpty(workbook.Sheets[sheetName])
          ? workbook.Sheets[sheetName]
          : undefined
      let version = 0

      if (versionSheet && !isEmpty(versionSheet) && versionSheet?.["B1"]?.v) {
        version = versionSheet?.["B1"]?.v
      } else {
        const versionsList = Object.keys(DRAFT_TEMPLATE_VERSIONS)
        version = Number(versionsList[versionsList?.length - 1]) ?? 0
      }

      const currentVersion = DRAFT_TEMPLATE_VERSIONS[version] ?? DRAFT_TEMPLATE_VERSIONS[0]!
      const versionProperties = currentVersion.properties

      // count number of columns with data
      // we need to check A2, B2, C2 etc. because the first row is the header
      // iterate by letters A, B, C, D etc.
      let columnCount = 0
      for (let i = 0; i < versionProperties.length; i++) {
        const letter = getColumnDescription(i)
        const cell = sheet[letter + currentVersion.headerRowIndex]
        if (cell && cell.v) {
          columnCount++
        } else {
          break
        }
      }

      if (columnCount !== versionProperties.length) {
        // To translate
        throw new Error(t("errors.draftUpload.templateErrorVersion"))
      }

      utils.sheet_add_aoa(sheet!, [versionProperties], { origin: currentVersion.origin })

      const table: any[] = utils
        .sheet_to_json(sheet!, { raw: true })
        .filter(
          (item: any) =>
            item.recipientName ||
            item.recipientAddress ||
            item.deliveryType ||
            item.date ||
            item.scheduledTime
        )

      if (table.length > MAX_UPLOAD_DRAFT_LIMIT) {
        throw new Error(
          t("errors.draftUpload.limitReached", { draftLimit: MAX_UPLOAD_DRAFT_LIMIT })
        )
      }

      return table
    } catch (err: any) {
      throw err
    }
  }

  const processTemplateFile = async (
    uploadCallback: (orders: DraftOrder[] | IQuotePayload[]) => Promise<any>
  ) => {
    try {
      setIsLoading(true)
      setProgressPercent(0)
      const parsedRows: ISheetRow[] | undefined = await convertFiletoJson()
      const submittedOrders = parsedRows?.filter(
        (row, index) => Object.keys(row)?.length >= 2 && row?.__rowNum__ - index === 1
      )

      if (!submittedOrders) {
        setIsLoading(false)
        return
      }
      if (submittedOrders?.length === 0) {
        throw new Error(t("errors.draftUpload.noOrdersFound"))
      }
      // We use a tuple with index to preserve original order
      const requestOrders = [] as { order: IQuotePayload; index: number }[]
      const processOrder = async (order: ISheetRow, index: number) => {
        const requestOrder = await convertSheetDataToQuotePayload(order)

        if (!requestOrder) {
          setIsLoading(false)
          return
        }

        const sameOrderIndex = requestOrders.findIndex(
          (ro) => ro.order.orderNumber === requestOrder.orderNumber
        )

        if (sameOrderIndex > -1 && requestOrder.deliveryType === ServerDeliveryType.FOURHOURS) {
          throw new Error(t("errors.draftUpload.multiple4hoursWithSameNumber"))
        }

        if (
          sameOrderIndex > -1 &&
          requestOrder.deliveryType &&
          [ServerDeliveryType.NEXT_DAY, ServerDeliveryType.NEXT_THREE_DAYS].includes(
            requestOrder.deliveryType
          )
        ) {
          throw new Error(t("errors.draftUpload.multipleNextDayWithSameNumber"))
        }

        if (sameOrderIndex > -1 && requestOrder.recipients?.[0]) {
          requestOrders[sameOrderIndex]?.order.recipients?.push(requestOrder.recipients[0])
          return
        }

        requestOrder.autoReassign = user?.preference?.platformReassign || false
        requestOrders.push({ order: requestOrder, index })
        // We set the progress percent to 99% because we will do a final upload callback
        setProgressPercent(Math.round((requestOrders.length / submittedOrders.length) * 100) - 1)
      }

      const hasOrdersWithClients = submittedOrders?.some((order) => !!order?.clientId)
      if (hasOrdersWithClients && isEmpty(enrichedClients)) {
        await fetchClients()
      }
      // Google Geocoding API limit is 50 requests per second
      // Potentially we need to move this to the backend
      const batchSize = Math.min(submittedOrders.length, 45)
      for (let i = 0; i < submittedOrders.length; i += batchSize) {
        await Promise.all(
          submittedOrders.slice(i, i + batchSize).map((order, index) => processOrder(order, index))
        )
        await new Promise((resolve) => setTimeout(resolve, 1000))
      }

      // Sort the requestOrders array back to its initial order
      requestOrders.sort((a, b) => a.index - b.index)
      const sortedOrders = requestOrders.map((item, index) => ({ ...item.order, index }))

      if (!isPublic) {
        const { data } = await createDraftOrders(sortedOrders)
        if (uploadCallback) {
          await uploadCallback(data)
        }
      } else {
        await uploadCallback(sortedOrders)
      }
      setFile([])
      setSelectedPickup(undefined)
      setIsLoading(false)
      return sortedOrders
    } catch (err: any) {
      console.log(err)
      setIsLoading(false)
      setFile([])
      throw err
    }
  }

  return {
    file,
    processTemplateFile,
    isLoading,
    progressPercent,
    setFile,
    isDisabled,
    setSelectedPickup,
    selectedPickupId: selectedPickup?.id,
  }
}
