import React, { Dispatch, SetStateAction, useState } from "react"
import { humanFileSize } from "@/utils/humanizeFileSize"
import { CloudUploadOutlined } from "@ant-design/icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faCircleInfo, faCircleXmark, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Typography, UploadProps } from "antd"
import { message, Upload } from "antd"
import { RcFile, UploadFile } from "antd/lib/upload"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { locationsSelector } from "@/atoms/userAtom"
import { PickupDetailsType } from "@/types/createSingleOrder.types"
import SelectPickupDropdown from "@/modules/new-orders/DraftOrderTable/components/BatchSelectors/components/SelectPickupDropdown"

const { Dragger } = Upload
const { Title } = Typography

export const XLSXFormatFile = {
  name: "XLSX",
  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}
export const KMLFormatFile = {
  name: "KML",
  type: "application/vnd.google-earth.kml+xml",
}
export const KMZFormatFile = {
  name: "KMZ",
  type: "application/vnd.google-earth.kmz",
}
export const CSVFormatFile = {
  name: "CSV",
  type: "text/csv",
}

interface DragAndUploadFileModuleProps {
  file: UploadFile[]
  setFile: (file: UploadFile[]) => void
  supportedFileTypes?: FileFormat[]
  hint?: string
  isDisabled: boolean
  toShowZonesWarning?: boolean
  setSelectedPickup?: Dispatch<SetStateAction<PickupDetailsType | undefined>>
  selectedPickupId?: string
}

interface FileItemProps {
  file: UploadFile
  uploadTime: number
  isDisabled: boolean
  remove: () => void
}

const isFileTypeSupported = (file: RcFile, supportedFileTypes: FileFormat[]) => {
  return (
    supportedFileTypes.length === 0 ||
    (supportedFileTypes.length &&
      supportedFileTypes.some(
        (t) => t.type === file.type || file.name.endsWith(t.name.toLocaleLowerCase())
      ))
  )
}

const beforeUpload = (file: RcFile, supportedFileTypes: FileFormat[]): boolean | string => {
  const isSupportedFormat = isFileTypeSupported(file, supportedFileTypes)
  if (!isSupportedFormat) {
    message.error(`You can only upload ${supportedFileTypes.map((t) => t.name).join(",")} file!`)
    return false
  }
  //1 MB
  const isBelowSizeLimit = file.size / 1024 / 1024 < 1
  if (!isBelowSizeLimit) {
    message.error("File must smaller than 1MB!")
    return false
  }
  return isSupportedFormat && isBelowSizeLimit ? true : Upload.LIST_IGNORE
}

const dummyRequest: (options: any) => void = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok")
  }, 0)
}

const FileItem: React.FC<FileItemProps> = ({ file, uploadTime, isDisabled, remove }) => {
  const { t } = useTranslation()

  return (
    <div id="file-item" className="mt-10">
      <Title level={5}>{t("new-orders.FileUpload.fileAdded")}</Title>
      <div className="flex">
        <span className="w-5 mr-5">
          <FontAwesomeIcon
            className="text-gray-400 mx-auto w-full h-full"
            icon={faFileExcel as IconProp}
          />
        </span>
        <div className="w-full">
          <div className="flex justify-between content-between w-full">
            <span id="file-name">{file.name}</span>
            <span
              id="file-remove"
              className={`flex items-center rounded-full w-5 h-5 ${
                !isDisabled ? "cursor-pointer" : "cursor-not-allowed"
              }`}
              onClick={() => {
                if (!isDisabled) {
                  remove()
                }
              }}
            >
              <FontAwesomeIcon
                className="text-gray-400 mx-auto w-full h-full"
                icon={faCircleXmark as IconProp}
              />
            </span>
          </div>
          <div className="flex justify-between content-between w-full">
            <span>
              {uploadTime
                ? DateTime.fromMillis(uploadTime).toRelative({
                    unit: "minutes",
                    style: "narrow",
                  })
                : ""}
            </span>
            <span>{humanFileSize(file.size)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

interface FileFormat {
  name: string
  type: string
}

const DragAndUploadFileModule: React.FC<DragAndUploadFileModuleProps> = ({
  isDisabled,
  file,
  setFile,
  toShowZonesWarning = false,
  supportedFileTypes = [XLSXFormatFile],
  setSelectedPickup,
  selectedPickupId,
  hint,
}) => {
  const { t } = useTranslation()

  const locations = useRecoilValue(locationsSelector)

  const [uploadTime, setUploadTime] = useState<number>(0)

  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    disabled: isDisabled,
    customRequest: dummyRequest,
    beforeUpload: (file: RcFile) => {
      return beforeUpload(file, supportedFileTypes)
    },
    onChange(info) {
      const { status } = info.file
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`)
        setUploadTime(DateTime.local().toMillis())
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
      setFile(info.fileList)
    },
    onDrop(_) {
      // console.log("Dropped files", e.dataTransfer.files)
    },
    onRemove(file) {
      console.log("Removed files", file.name)
      setFile([])
    },
    fileList: file,
    itemRender: (_, file, __, actions) => {
      const { remove } = actions
      return (
        <FileItem file={file} uploadTime={uploadTime} isDisabled={isDisabled} remove={remove} />
      )
    },
  }

  return (
    <div className="flex flex-col w-full gap-y-4">
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p className="ant-upload-text">{t("new-orders.FileUpload.selectAfileOrDrag")}</p>
        {hint && <p className="ant-upload-hint">{t(hint)}</p>}
      </Dragger>
      {setSelectedPickup && (
        <div className="flex w-1/2">
          <SelectPickupDropdown
            handleStore={async (locationId: string) => {
              setSelectedPickup(locations?.find((location) => location.id === locationId))
            }}
            isShortDescription
            selectedStore={selectedPickupId || null}
            disabled={false}
            loading={false}
            onClear={() => setSelectedPickup(undefined)}
            isOptional={true}
            showEmptyButton={true}
          />
        </div>
      )}
      {toShowZonesWarning && (
        <div className="flex mt-4 justify-end align-middle">
          <FontAwesomeIcon
            className="text-blue-500 h-full"
            style={{ marginTop: "0.2rem" }}
            icon={faCircleInfo as IconProp}
          />
          <span className="ml-2 text-sm">{t("zones.ImportZonesModal.warning")}</span>
        </div>
      )}
    </div>
  )
}

export default DragAndUploadFileModule
