import { atom, selector, selectorFamily } from "recoil"
import { GlobalOrdersMetadata, IOrder, TableOrder, TableOrderChild } from "@/types/orders.types"

export interface IOrdersAtom {
  orders: IOrder[]
  metadata: GlobalOrdersMetadata
}

export const defaultMetadata = {
  total_successful_deliveries: 0,
  total_successful_deliveries_cost: 0,
  avg_cost_per_successful_deliveries: 0,
  total: 0,
  created: 0,
  totalRoutes: 0,
  totalStops: 0,
  to_pickup: 0,
  picked_up: 0,
  delivered: 0,
  cancelled: 0,
  returning: 0,
  returned: 0,
  at_hub: 0,
  to_dropoff: 0,
  cost: 0,
  delivery_delayed: 0,
  pickup_delayed: 0,
  not_accepted: 0,
  route_duration_limit: 0,
  route_vehicle_capacity_limit: 0,
  deliveriesPerDriver: {},
  submitted: 0,
  delayed: 0,
  driver_rejected: 0,
  no_available_drivers: 0,
  byLocationId: {}
}

export const ordersAtom = atom({
  key: "orders",
  default: {
    orders: [] as IOrder[],
    metadata: defaultMetadata
  } as IOrdersAtom,
})

export const formatOrderChildren = (order: IOrder): TableOrderChild[] | undefined => {
  return order.recipients.length <= 1 ? undefined : order.recipients.map((recipient, index) => ({
    key: recipient.trackingUrl,
    orderID: recipient.id || index,
    stopIndex: index,
    parentId: order.id,
    // TODO: this might be confusing, the naming of etas/planned are not the same but needs to to be displayed in DropoffList
    plannedArrivalAt: recipient.dropoffEta,
    dropoffEta: recipient.dropoffEta,
    expectedArrival: recipient.expectedArrival,
    parentStatus: order.status,
    trackingUrl: recipient.trackingUrl,
    provider: order.provider,
    sender: order.sender,
    recipients: order.recipients,
    recipient: recipient,
    index,
    status: recipient.status,
    deliveryType: order.deliveryType,
    orderReferenceId: recipient.orderReferenceId,
    availableStatuses: order.availableStatuses,
    isChildren: true,
    type: recipient.type,
    currency: order.currency,
  }))
}

export const tableOrdersSelector = selector({
  key: "tableOrdersSelector",
  get: ({ get }) => {
    const { orders } = get(ordersAtom)

    return orders.map((order) => ({
      key: order?.id,
      ...order,
      isChildren: false,
      children: formatOrderChildren(order),
    })) as TableOrder[]
  }
})

export const globalOrdersMetadataSelector = selector({
  key: "globalOrdersMetadataSelector",
  get: ({ get }) => {
    const { metadata } = get(ordersAtom)

    return metadata
  }
})

export const getSingleOrderSelector = selectorFamily({
  key: "getSingleOrderSelector",
  get: (orderId: string | null) => ({ get }) => {
    const orders = get(tableOrdersSelector)
    return orders?.find((order) => order.id === orderId)
  }
})

export const orderIdsSelector = selector({
  key: "ordersIds",
  get: ({ get }) => {
    const { orders } = get(ordersAtom)
    return orders?.map((order) => order.id) || []
  }
})
