import { ParcelDimensions } from "@/types/orders.types"

export const getParcelDimensionsText = (dimensions: ParcelDimensions) => {
  let dimensionsText = ""
  if (dimensions.length && dimensions.width && dimensions.height) {
    dimensionsText = `${dimensions.length}x${dimensions.width}x${dimensions.height}cm`
    return dimensions.weight
      ? `${dimensionsText} (${getParcelWeightText(dimensions)})`
      : dimensionsText
  }

  return dimensions.weight ? getParcelWeightText(dimensions) : ""
}

export const getParcelWeightText = (dimensions: ParcelDimensions) => {
  if (!dimensions.weight) {
    return ""
  }

  return `<${dimensions.weight} kg`
}

export const getParcelDimensionsType = (dimensions?: ParcelDimensions) => {
  if (!dimensions) {
    return null
  }
  return `${dimensions.length}cm x ${dimensions.width}cm x ${dimensions.height}cm / < ${dimensions.weight}kg`
}

export const parseParcelDimensions = (dimensionsText: string) => {
  const parsedDimensions = dimensionsText.split(/cm x |cm \/ |< |kg/).filter((item) => !!item)
  const [length, width, height, weight] = parsedDimensions
  const dimensions: ParcelDimensions = {
    length: Number(length),
    width: Number(width),
    height: Number(height),
    weight: Number(weight),
  }

  return dimensions
}

export const parseParcelDimensionsV2 = (dimensionsText: string) => {
  const parsedDimensions = dimensionsText.split(/cm x |cm/).filter((item) => !!item)
  const [length, width, height] = parsedDimensions
  const dimensions: ParcelDimensions = {
    length: Number(length),
    width: Number(width),
    height: Number(height),
    weight: 0,
  }

  return dimensions
}
