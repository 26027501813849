import { Coordinates } from "./map"
import { VehicleType } from "./vehicle.types"

export interface DriverAttachment {
  expiresAt: number
  name: string
  url: string
}

export interface Driver {
  id: string
  name: string
  phone: string
  email: string
  vehicleType: VehicleType
  lengthCapacity?: number
  heightCapacity?: number
  widthCapacity?: number
  weightCapacity?: number
  locationID?: string
  teamIDs?: number[]
  status: "online" | "offline" | "idle"
  plateNumber?: string,
  photoUrl?: string,
  latitude?: number,
  longitude?: number,
  locationUpdatedAt?: number,
  lastUpdated?: number,
  config?: OwnFleetDriverConfig
  preventAnimation?: boolean
  disabled?: boolean
  attachments?: DriverAttachment[]
}

export interface DriverMapPin extends Driver {
  onDriverPinClick?: () => void
  onDriverPinCloseClick?: () => void
}

export interface OwnFleetDriverConfig {
  routeStartAddress?: string
  routeStartLocation?: Coordinates
  routeEndAddress?: string
  routeEndLocation?: Coordinates
  maxBatchSize?: number
  maxDistanceInMeters?: number
  maxShiftTimeInMinutes?: number
}

export type DriverFormValues = Driver & {
  defaultRouteStartLocation?: boolean
  routeStartAddress?: string
  routeStartLocation?: Coordinates
  defaultRouteEndLocation?: boolean
  routeEndAddress?: string
  routeEndLocation?: Coordinates
  defaultMaxBatchSize?: boolean
  maxBatchSize?: number
  defaultMaxDistance?: boolean
  maxDistanceInKm?: number
  defaultMaxShiftTime?: boolean
  maxShiftTime?: {
    hours: number
    minutes: number
  }
}

export enum DriverFormTab {
  PROFILE = "profile",
  CONFIG = "config",
  DOCUMENTS = "documents"
}
