export const PORTAL_XLSX_TEMPLATE_LINK = "https://static.kosmo.delivery/docs/prod/KosmoOrderPortalTemplate.xlsx"
export const PORTAL_GOOGLE_SHEETS_TEMPLATE_LINK = "https://docs.google.com/spreadsheets/d/1k60qL7AhvnXycBzZ4TS42XQ9LFSb5oXh7xh0k8GCmkY/"

export const DRAFT_XLSX_TEMPLATE_LINK_EN = "https://static.kosmo.delivery/docs/prod/KosmoTemplateUploadFile.xlsx"
export const DRAFT_XLSX_TEMPLATE_LINK_ES = "https://static.kosmo.delivery/docs/prod/KosmoTemplateUploadFile_es.xlsx"

export const DRAFT_GOOGLE_SHEETS_TEMPLATE_LINK_EN = "https://docs.google.com/spreadsheets/d/1wzlfkn6lp9dcu7uhohqAuYuMrw4SWDrmNAy5zkBVZoA/edit?gid=1981532844#gid=1981532844"
export const DRAFT_GOOGLE_SHEETS_TEMPLATE_LINK_ES = "https://docs.google.com/spreadsheets/d/1EdeKSsOTctb2zZBDdGMWhasqFoJ3spxMXGseelw_S_w/edit?gid=1981532844#gid=1981532844"

export const DRIVER_GOOGLE_SHEETS_TEMPLATE_LINK_EN = "https://docs.google.com/spreadsheets/d/1gTIUnca3T_fQsZ5EWfSVMCLcB4oCmZSELGOv6qaVpuk/edit?gid=1525749526#gid=1525749526"
export const DRIVER_GOOGLE_SHEETS_TEMPLATE_LINK_ES = "https://docs.google.com/spreadsheets/d/1ipoSCckJfQE_0Kxz35Gk_bWL6y2VMxOWQidO_ef6UlM/edit?gid=1525749526#gid=1525749526"