import { userAtom } from "@/atoms/userAtom"
import { Branding } from "@/types/organizations.types"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"

export const BASKIT_BRANDING: Branding = {
  platformEnabled: true,
  companyLogoUrl: "/assets/images/baskit.png",
  primaryColor: "#FF8B00",
  secondaryColor: undefined,
  textColor: undefined,
  width: 50,
  height: 24,
}

const InjectBranding: React.FC<{}> = () => {
  const setUser = useSetRecoilState(userAtom)
  useEffect(() => {
    if (window) {
      localStorage.setItem("origin", window.location.origin)
      if (window.location.origin?.includes("baskit")) {
        setUser((user) => ({
          ...user,
          preference: {
            ...user?.preference,
            branding: BASKIT_BRANDING,
            platformEnabled: true,
          },
          customSubdomain: "baskit",
        }))
      }
    }
  }, [window])

  return <></>
}

export default InjectBranding
