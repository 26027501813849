import { v0Properties } from "./versions/version_0"
import { v16Properties } from "./versions/version_16"
import { v17Properties } from "./versions/version_17"
import { v18Properties } from "./versions/version_18"
import { v19Properties } from "./versions/version_19"
import { v20Properties } from "./versions/version_20"
import { v21Properties } from "./versions/version_21"
import { v22Properties } from "./versions/version_22"
import { v23Properties } from "./versions/version_23"
import { v24Properties } from "./versions/version_24"
import { v25Properties } from "./versions/version_25"
import { v26Properties } from "./versions/version_26"
import { v27Properties } from "./versions/version_27"
import { v28Properties } from "./versions/version_28"
import { v29Properties } from "./versions/version_29"

// Start is the row at which parsing should start (titles)
export const DRAFT_TEMPLATE_VERSIONS: Record<number, { properties: string[], origin: 'A1' | 'A2', headerRowIndex: 1 | 2 }> = {
  0: { properties: v0Properties, origin: 'A2', headerRowIndex: 2 },
  16: { properties: v16Properties, origin: 'A2', headerRowIndex: 2 },
  17: { properties: v17Properties, origin: 'A2', headerRowIndex: 2 },
  18: { properties: v18Properties, origin: 'A2', headerRowIndex: 2 },
  19: { properties: v19Properties, origin: 'A2', headerRowIndex: 2 },
  20: { properties: v20Properties, origin: 'A2', headerRowIndex: 2 },
  21: { properties: v21Properties, origin: 'A2', headerRowIndex: 2 },
  22: { properties: v22Properties, origin: 'A2', headerRowIndex: 2 },
  23: { properties: v23Properties, origin: 'A2', headerRowIndex: 2 },
  24: { properties: v24Properties, origin: 'A1', headerRowIndex: 1 },
  25: { properties: v25Properties, origin: 'A1', headerRowIndex: 1 },
  26: { properties: v26Properties, origin: 'A1', headerRowIndex: 1 },
  27: { properties: v27Properties, origin: 'A1', headerRowIndex: 1 },
  28: { properties: v28Properties, origin: 'A1', headerRowIndex: 1 },
  29: { properties: v29Properties, origin: 'A1', headerRowIndex: 1 },
}
