import { userAtom } from "@/atoms/userAtom"
import {
  OptimizationSummary,
  DraftOrder,
  DraftOrderOptimizationResult,
} from "@/types/draftOrder.types"
import { Driver } from "@/types/drivers.types"
import { UNASSIGNED_KEY_SLUG } from "@/types/map"
import { humanizeDistance } from "@/utils/distance"
import { Modal, Button, Divider } from "antd"
import { isEmpty, uniq } from "lodash"
import Link from "next/link"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import OptimizationRouteDetails from "./components/OptimizationRouteDetails"
import { getHumanizedTime } from "@/utils/datetime"
import { Dispatch, SetStateAction } from "react"

interface OptimizationSummaryModalProps {
  optimizationSummary?: OptimizationSummary | null
  setOptimizationSummary: Dispatch<SetStateAction<DraftOrderOptimizationResult | null>>
  drivers: Driver[]
  draftOrders: DraftOrder[]
}

const OptimizationSummaryModal = ({
  setOptimizationSummary,
  optimizationSummary,
  drivers,
  draftOrders,
}: OptimizationSummaryModalProps) => {
  const { t } = useTranslation()
  const { preference } = useRecoilValue(userAtom)

  // if at least one route has ID that cannot be found in draftOrders, we don't show the modal
  const hasInvalidRoute = optimizationSummary?.routes.some(
    (route) => !draftOrders.find((order) => order.id === route.orderId)
  )

  if (hasInvalidRoute) return null

  // Build the map redirection querystring
  const mapNodeKeys = uniq(
    optimizationSummary?.routes?.map((route) => {
      const order = draftOrders.find((order) => order.id === route.orderId)
      const isUnassigned = !order?.metadata?.driverId
      const routeSlug = isUnassigned
        ? UNASSIGNED_KEY_SLUG
        : `${order?.metadata?.teamId ?? "org"}:${order?.metadata?.driverId}`
      return routeSlug
    }) || []
  )
    .filter((key) => !!key)
    .join("/")

  const orderKeys = optimizationSummary?.routes?.map((route) => route?.orderId)?.join("/")

  return (
    <Modal
      title={t("new-orders.BatchSelectors.optimizationSummary")}
      open={!!optimizationSummary && !isEmpty(optimizationSummary)}
      onCancel={() => setOptimizationSummary(null)}
      footer={
        <div className="flex w-full space-x-2 justify-end">
          <Button onClick={() => setOptimizationSummary(null)}>{t("common.close")}</Button>
          <Link href={`/map?ids=${orderKeys}&keys=${mapNodeKeys}`}>
            <Button
              type="primary"
              onClick={() => {
                setOptimizationSummary(null)
              }}
              href={`/map?ids=${orderKeys}&keys=${mapNodeKeys}`}
            >
              {t("common.seeInMap")}
            </Button>
          </Link>
        </div>
      }
      okText={t("common.close")}
      className="optimization-summary-modal"
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="font-bold">{t("new-orders.BatchSelectors.optimizationSummaryTotal")}</p>
          <div className="font-bold">
            <p className="flex justify-end">
              {humanizeDistance(optimizationSummary?.totalDistance ?? 0, preference.distanceUnit)}
            </p>
            <p className="flex justify-end">
              {getHumanizedTime(optimizationSummary?.totalDuration ?? 0)}
            </p>
          </div>
        </div>
        {!isEmpty(optimizationSummary?.routes) && (
          <>
            <Divider style={{ margin: "0.3em" }} />
            <p className="font-bold">{t("common.routes")}</p>
            <div className="flex flex-col gap-2">
              {optimizationSummary?.routes.map((route, index) => {
                const routeDraftOrder = draftOrders.find((order) => order.id === route.orderId)
                if (!routeDraftOrder) return <></>
                return (
                  <OptimizationRouteDetails
                    route={{ ...route, ...routeDraftOrder }}
                    index={index}
                    driverName={
                      drivers.find((driver) => driver.id === routeDraftOrder.metadata?.driverId)
                        ?.name
                    }
                  />
                )
              })}
            </div>
          </>
        )}
        <Divider style={{ margin: "0.3em" }} />
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-between">
            <div>{t("new-orders.BatchSelectors.optimizationSummaryUnassigned")}</div>
            <div>
              <div className="flex justify-end">{optimizationSummary?.unassigned.count}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OptimizationSummaryModal
