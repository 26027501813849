import axios from "@/modules/shared/AxiosInterceptor"
import { Driver } from "@/types/drivers.types"

import { AxiosResponse } from "axios"

export async function postDriver(driver: Driver): Promise<AxiosResponse<Driver>> {
  return axios
    .post<Driver>("/ownfleet/drivers", driver)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getDrivers() {
  return axios
    .get<{ drivers: Driver[] }>("/ownfleet/drivers")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function exportDrivers() {
  return axios
    .get("/ownfleet/drivers/export/csv")
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function deleteDriver(id: string) {
  return axios
    .delete(`/ownfleet/drivers/${id}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function shareOrderDetails(orderId: string, transport: string) {
  return axios
    .post(`/ownfleet/drivers/share-order-details`, {
      orderId,
      transport,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function changeDriver(orderId: string, driverId: string) {
  return axios
    .post(`/ownfleet/drivers/change-driver`, {
      orderId,
      driverId,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function putDriver(driver: Driver): Promise<AxiosResponse<Driver>> {
  return axios
    .put<Driver>("/ownfleet/drivers/" + driver.id, driver)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
