const formatFullName = (firstName: string = "", lastName: string = "") => {
  return firstName || lastName ? `${firstName} ${lastName}` : ""
}

const splitFullName = (name?: string) => {
  if (!name) {
    return { firstName: "", lastName: "" }
  }
  const firstName = name.trim().split(" ").slice(0, -1).join(" ")
  const lastName = name.trim().split(" ").slice(-1).join(" ")
  return { firstName, lastName }
}

export { formatFullName, splitFullName }
