import { Driver, DriverFormValues } from "@/types/drivers.types";
import { VehicleType } from "@/types/vehicle.types";


export const VEHICLES = [VehicleType.BIKE, VehicleType.MOTORCYCLE, VehicleType.CAR, VehicleType.VAN, VehicleType.LORRY]

export function onPrepareDriverData(formValues: DriverFormValues, onSetIsStartAddressValid: (value: boolean) => void, onSetIsEndAddressValid: (value: boolean) => void): Driver | null {
  const { defaultRouteStartLocation, routeStartAddress, routeStartLocation, defaultRouteEndLocation, routeEndAddress, routeEndLocation, defaultMaxBatchSize, maxBatchSize, defaultMaxDistance, maxDistanceInKm, defaultMaxShiftTime, maxShiftTime, ...rest } = formValues;

  if (!defaultRouteEndLocation && !routeEndLocation) {
    onSetIsStartAddressValid(false);
    onSetIsEndAddressValid(false);
    return null;
  }

  const config = {
    routeStartAddress: defaultRouteStartLocation ? undefined : routeStartAddress,
    routeStartLocation: defaultRouteStartLocation ? undefined : routeStartLocation,
    routeEndAddress: defaultRouteEndLocation ? undefined : routeEndAddress,
    routeEndLocation: defaultRouteEndLocation ? undefined : routeEndLocation,
    maxBatchSize: defaultMaxBatchSize ? undefined : Number(maxBatchSize ?? 0),
    maxDistanceInMeters: defaultMaxDistance ? undefined : Number(maxDistanceInKm ?? 0) * 1000,
    maxShiftTimeInMinutes: defaultMaxShiftTime ? undefined : Number(maxShiftTime?.hours ?? 0) * 60 + Number(maxShiftTime?.minutes ?? 0),
  };

  return {
    ...rest,
    lengthCapacity: rest.lengthCapacity !== undefined ? Number(rest.lengthCapacity) : undefined,
    widthCapacity: rest.widthCapacity !== undefined ? Number(rest.widthCapacity) : undefined,
    heightCapacity: rest.heightCapacity !== undefined ? Number(rest.heightCapacity) : undefined,
    weightCapacity: rest.weightCapacity !== undefined ? Number(rest.weightCapacity) : undefined,
    config,
  };
}

export const getCapacityText = (dimensions: {
  length?: number,
  width?: number,
  height?: number,
  weight?: number
}) => {
  let dimensionsText = ""
  if (dimensions.length && dimensions.width && dimensions.height) {
    dimensionsText = `${dimensions.length}x${dimensions.width}x${dimensions.height}cm`
    return dimensions.weight
      ? `${dimensionsText} (${getCapacityWeightText(dimensions.weight)})`
      : dimensionsText
  }

  return dimensions.weight ? getCapacityWeightText(dimensions.weight) : ""
}

export const getCapacityWeightText = (
  weight?: number) => {
  if (!weight) {
    return ""
  }

  return `${weight} kg`
}