import React, { useEffect, useState } from "react"
import { Driver, DriverFormTab, DriverFormValues } from "@/types/drivers.types"
import { Form, Image, Input, Select, Switch, Tabs, TabsProps, Tooltip, message } from "antd"
import { useTranslation } from "react-i18next"
import { OptimizationConfig, defaultOptimizationConfig } from "@/types/optimization"
import { getOptimizationConfig } from "@/api/optimization"
import { useDrivers } from "@/hooks/useDrivers"
import { useTeams } from "@/hooks/useTeams"
import useInputPhoneNumber from "@/hooks/useInputPhoneNumber"
import { capitalize, isEmpty } from "lodash"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { VehicleType } from "@/types/vehicle.types"
import { VEHICLES, onPrepareDriverData } from "../utils"
import {
  AddressAutocomplete,
  FloatInput,
  PhoneFormItem,
  SecondaryText,
  SemiBoldLabelText,
  SenderNameFormItem,
} from "@/UI"
import { EmailFormItem } from "@/UI/Forms/FormItems/EmailFormItem"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import VehicleTypeSelector from "@/modules/shared/components/VehicleTypeSelector"
import {
  faWeightHanging,
  faRulerHorizontal,
  faRulerVertical,
} from "@fortawesome/free-solid-svg-icons"
import { IPrediction } from "@/hooks/autocomplete/GooglePlacesAutocomplete.types"
import BrandedQuestionMark from "@/UI/Icons/BrandedQuestionMark"
import { useGeocoding } from "@/hooks/useGeocoding"
import { useFlags } from "flagsmith/react"
import FakeDocumentUpload from "./FakeDocumentsUpload"

type DriverFormProps = {
  cancelCallback: () => void
  driver?: Driver
  defaultTeamId?: number
}

export default function DriverForm({ cancelCallback, driver, defaultTeamId }: DriverFormProps) {
  const formForAdd = driver === undefined

  const { t } = useTranslation()
  const flags = useFlags(["show_driver_start_location", "nash_edg_demo"])
  const [form] = Form.useForm<DriverFormValues>()
  const { geocodeByAddress } = useGeocoding()
  const [activeTab, setActiveTab] = useState<DriverFormTab>(DriverFormTab.PROFILE)
  {
    /* ENG DEMO */
  }
  const { drivers } = useDrivers()
  const attachments = drivers?.find((driverState) => driverState?.id === driver?.id)?.attachments
  {
    /* END ENG DEMO */
  }
  const [optimizationConfig, setOptimizationConfig] =
    useState<OptimizationConfig>(defaultOptimizationConfig)
  const fetchOptimizationConfig = async () => {
    try {
      const { data } = await getOptimizationConfig()
      setOptimizationConfig(data)
    } catch (error) {
      console.log(error)
    } finally {
    }
  }
  const { createDriver, updateDriver, isLoading } = useDrivers()
  const { teams } = useTeams()
  const { countryCode, handlePhoneSelect, phoneCode } = useInputPhoneNumber(form, "phone")

  const teamsOptions = teams?.map((team) => ({
    value: team.id,
    key: team.id,
    label: capitalize(team?.name),
  }))

  useEffect(() => {
    if (formForAdd) {
      form.setFieldsValue({
        vehicleType: VehicleType.MOTORCYCLE,
        phone: formatPhoneNumber(phoneCode),
        ...(defaultTeamId ? { teamIDs: [defaultTeamId] } : {}),
        defaultRouteStartLocation: true,
        defaultRouteEndLocation: true,
        defaultMaxBatchSize: true,
        defaultMaxDistance: true,
        defaultMaxShiftTime: true,
      })
    } else {
      form.setFieldsValue({
        ...driver,
        lengthCapacity:
          driver.lengthCapacity && driver.lengthCapacity > 0 ? driver.lengthCapacity : undefined,
        widthCapacity:
          driver.widthCapacity && driver.widthCapacity > 0 ? driver.widthCapacity : undefined,
        heightCapacity:
          driver.heightCapacity && driver.heightCapacity > 0 ? driver.heightCapacity : undefined,
        weightCapacity:
          driver.weightCapacity && driver.weightCapacity > 0 ? driver.weightCapacity : undefined,
        defaultRouteStartLocation: driver.config?.routeStartLocation ? false : true,
        routeStartAddress: driver.config?.routeStartAddress,
        routeStartLocation: driver.config?.routeStartLocation,
        defaultRouteEndLocation: driver.config?.routeEndLocation ? false : true,
        routeEndAddress: driver.config?.routeEndAddress,
        routeEndLocation: driver.config?.routeEndLocation,
        defaultMaxBatchSize: (driver.config?.maxBatchSize ?? 0) === 0,
        maxBatchSize: driver.config?.maxBatchSize,
        defaultMaxDistance: (driver.config?.maxDistanceInMeters ?? 0) === 0,
        maxDistanceInKm:
          driver.config?.maxDistanceInMeters !== undefined
            ? driver.config?.maxDistanceInMeters / 1000
            : undefined,
        defaultMaxShiftTime: (driver.config?.maxShiftTimeInMinutes ?? 0) === 0,
        maxShiftTime:
          driver.config?.maxShiftTimeInMinutes !== undefined
            ? {
                hours: Math.floor((driver.config?.maxShiftTimeInMinutes ?? 0) / 60),
                minutes: (driver.config?.maxShiftTimeInMinutes ?? 0) % 60,
              }
            : undefined,
      })
    }

    fetchOptimizationConfig()
  }, [])

  const lengthCapacity = Form.useWatch("lengthCapacity", form)
  const widthCapacity = Form.useWatch("widthCapacity", form)
  const heightCapacity = Form.useWatch("heightCapacity", form)
  const weightCapacity = Form.useWatch("weightCapacity", form)
  const teamIDs = Form.useWatch("teamIDs", form)

  const withDefaultRouteStartLocation = Form.useWatch("defaultRouteStartLocation", form)
  const routeStartAddress = Form.useWatch("routeStartAddress", form)
  useEffect(() => {
    if (withDefaultRouteStartLocation) {
      form.resetFields(["routeStartAddress", "routeStartLocation"])
    }
  }, [withDefaultRouteStartLocation])
  const withDefaultRouteEndLocation = Form.useWatch("defaultRouteEndLocation", form)
  const routeEndAddress = Form.useWatch("routeEndAddress", form)
  useEffect(() => {
    if (withDefaultRouteEndLocation) {
      form.resetFields(["routeEndAddress", "routeEndLocation"])
    }
  }, [withDefaultRouteEndLocation])

  const [isStartAddressValid, setIsStartAddressValid] = useState(true)
  const [isEndAddressValid, setIsEndAddressValid] = useState(true)

  const withDefaultMaxBatchSize = Form.useWatch("defaultMaxBatchSize", form)
  const maxBatchSize = Form.useWatch("maxBatchSize", form)
  useEffect(() => {
    if (withDefaultMaxBatchSize) {
      form.resetFields(["maxBatchSize"])
    }
  }, [withDefaultMaxBatchSize])

  const withDefaultMaxDistance = Form.useWatch("defaultMaxDistance", form)
  const maxDistanceInKm = Form.useWatch("maxDistanceInKm", form)
  useEffect(() => {
    if (withDefaultMaxDistance) {
      form.resetFields(["maxDistanceInKm"])
    }
  }, [withDefaultMaxDistance])

  const withDefaultMaxShiftTime = Form.useWatch("defaultMaxShiftTime", form)
  const maxShiftTime = Form.useWatch("maxShiftTime", form)
  useEffect(() => {
    if (withDefaultMaxShiftTime) {
      form.resetFields(["maxShiftTime"])
    }
  }, [withDefaultMaxShiftTime])

  const selectedVehicle = Form.useWatch("vehicleType", form)
  const handleSelectVehicle = (vehicleType: VehicleType) => {
    form.setFieldValue("vehicleType", vehicleType)
  }

  const onStartAddressSelected = async (prediction: IPrediction) => {
    try {
      const geocodeResult = await geocodeByAddress(prediction.description)
      form.setFieldValue("routeStartAddress", prediction.description)
      if (!geocodeResult) {
        throw "No result available"
      }
      setIsStartAddressValid(true)
      const coordinates: { lat: number; lng: number } = geocodeResult?.location
      form.setFieldValue("routeStartLocation", {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      })
    } catch (error) {
      setIsStartAddressValid(false)
    }
  }

  const onEndAddressSelected = async (prediction: IPrediction) => {
    try {
      const geocodeResult = await geocodeByAddress(prediction.description)
      form.setFieldValue("routeEndAddress", prediction.description)
      if (!geocodeResult) {
        throw "No result available"
      }
      setIsEndAddressValid(true)
      const coordinates: { lat: number; lng: number } = geocodeResult?.location
      form.setFieldValue("routeEndLocation", {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
      })
    } catch (error) {
      setIsEndAddressValid(false)
    }
  }

  const onFinish = async (formValues: DriverFormValues) => {
    try {
      const postData = onPrepareDriverData(formValues, setIsStartAddressValid, setIsEndAddressValid)
      if (!postData) return

      if (!formForAdd) {
        postData.id = driver.id
      }
      if (formForAdd) {
        await createDriver(postData)
        message.success(t("profile.DriversSettings.successfullyAddedDriver"))
      } else {
        await updateDriver(postData)
        message.success(t("profile.DriversSettings.successfullyUpdatedDriver"))
      }
      cancelCallback()
    } catch (error: any) {
      if (formForAdd) {
        if (error?.response?.data?.message?.includes("eature not available")) {
          cancelCallback()
          message.error(t("errors.maximumNumberOfDrivers"))
          return
        }
        message.error(t("profile.DriversSettings.errorAddingDriverToFleet"))
      } else {
        message.error(t("profile.DriversSettings.errorUpdatingDriver"))
      }
    }
  }

  const tabItems: TabsProps["items"] = [
    {
      key: DriverFormTab.PROFILE,
      label: t("profile.DriversSettings.profile"),
    },
    {
      key: DriverFormTab.CONFIG,
      label: t("profile.DriversSettings.routeSettings"),
    },
    ...(!!driver?.id && flags.nash_edg_demo.enabled
      ? [
          {
            key: DriverFormTab.DOCUMENTS,
            label: t("Documents"),
          },
        ]
      : []),
  ]

  const isModifying = formForAdd
    ? true
    : (driver.lengthCapacity ?? 0) !== (lengthCapacity ?? 0) ||
      (driver.widthCapacity ?? 0) !== (widthCapacity ?? 0) ||
      (driver.heightCapacity ?? 0) !== (heightCapacity ?? 0) ||
      (driver.weightCapacity ?? 0) !== (weightCapacity ?? 0) ||
      JSON.stringify(driver.teamIDs) !== JSON.stringify(teamIDs) ||
      driver.vehicleType != selectedVehicle ||
      driver.config?.routeStartAddress !== routeStartAddress ||
      driver.config?.routeEndAddress !== routeEndAddress ||
      (driver.config?.maxBatchSize ?? 0) !== (maxBatchSize ?? 0) ||
      (driver.config?.maxDistanceInMeters ?? 0) !== (maxDistanceInKm ?? 0) * 1000 ||
      (driver.config?.maxShiftTimeInMinutes ?? 0) !==
        (maxShiftTime?.hours ?? 0) * 60 + (maxShiftTime?.minutes ?? 0)

  return (
    <Form
      id="driver-form"
      form={form}
      layout="vertical"
      validateTrigger="onSubmit"
      onFinish={onFinish}
    >
      <Tabs
        activeKey={activeTab}
        items={tabItems}
        onChange={(key) => {
          setActiveTab(key as DriverFormTab)
        }}
      />
      {/* ENG Demo */}
      <div
        className={`${
          activeTab === DriverFormTab.DOCUMENTS ? "flex" : "hidden"
        } flex-col gap-y-2 mt-2 mb-4`}
      >
        {!!driver?.id && !!flags.nash_edg_demo.enabled && (
          <Form.Item name="attachments">
            <SemiBoldLabelText>{t("Documents")}</SemiBoldLabelText>
            <FakeDocumentUpload
              driverId={driver?.id}
              button={
                <div className="flex gap-x-2 cursor-pointer items-center w-fit">
                  <p>+</p>
                  <p>{t("common.addADocument")}</p>
                </div>
              }
            />
            {attachments && attachments?.length > 0 ? (
              attachments?.map((attachment) => {
                return <Image src={attachment?.url} width={45} height={45} />
              })
            ) : (
              <></>
            )}
          </Form.Item>
        )}
      </div>
      {/* END ENG Demo */}
      <div className={`${activeTab === DriverFormTab.PROFILE ? "flex" : "hidden"} flex-col`}>
        <SenderNameFormItem
          name="name"
          placeholder={t("common.fullName")}
          label={t("common.fullName")}
          validationErrorText={t("common.forms.enterName")}
          disabled={!formForAdd}
        />
        <EmailFormItem
          name="email"
          placeholder={`${t("common.forms.emailAddress")} (${t("common.optional")})`}
          disabled={!formForAdd}
        />
        <PhoneFormItem
          handlePhoneSelect={handlePhoneSelect}
          countryCode={countryCode || "SG"}
          disabled={!formForAdd}
          skipValidation={!formForAdd}
        />
        <div className="flex flex-col">
          <div className="flex items-center gap-x-2">
            <SemiBoldLabelText>{t("common.capacity")}</SemiBoldLabelText>
            <Tooltip
              title={t("profile.DriversSettings.capacityTooltip")}
              overlayStyle={{ zIndex: 3001 }}
            >
              <BrandedQuestionMark className="mb-1" />
            </Tooltip>
          </div>
          <div className="flex gap-x-2">
            <Form.Item name="lengthCapacity" className="w-full">
              <FloatInput
                name="lengthCapacity"
                prefixIcon={faRulerHorizontal}
                label={capitalize(t("common.dimensions.length") + " (cm)")}
                placeholder={capitalize(t("common.dimensions.length") + " (cm)")}
                type="number"
              />
            </Form.Item>
            <Form.Item name="heightCapacity" className="w-full">
              <FloatInput
                name="heightCapacity"
                prefixIcon={faRulerVertical}
                label={capitalize(t("common.dimensions.height") + " (cm)")}
                placeholder={capitalize(t("common.dimensions.height") + " (cm)")}
                type="number"
              />
            </Form.Item>
          </div>
          <div className="flex gap-x-2">
            <Form.Item name="widthCapacity" className="w-full">
              <FloatInput
                name="widthCapacity"
                prefixIcon={faRulerHorizontal}
                label={capitalize(t("common.dimensions.width") + " (cm)")}
                placeholder={capitalize(t("common.dimensions.width") + " (cm)")}
                type="number"
              />
            </Form.Item>
            <Form.Item name="weightCapacity" className="w-full">
              <FloatInput
                name="weightCapacity"
                prefixIcon={faWeightHanging}
                label={capitalize(t("common.dimensions.weight") + " (kg)")}
                placeholder={capitalize(t("common.dimensions.weight") + " (kg)")}
                type="number"
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item name="teamIDs">
          <Select
            id="select-teams"
            className="!cursor-pointer"
            options={teamsOptions}
            showSearch={false}
            mode="multiple"
            placeholder={`${t("common.team")} (${t("common.optional")})`}
            disabled={isEmpty(teams)}
            dropdownStyle={{ zIndex: 3001 }}
            defaultValue={defaultTeamId ? [defaultTeamId] : undefined}
          />
        </Form.Item>
        <Form.Item name="vehicleType">
          <SemiBoldLabelText>{t("common.vehicle")}</SemiBoldLabelText>
          <VehicleTypeSelector
            vehicles={VEHICLES}
            selectedVehicleType={selectedVehicle}
            onClick={handleSelectVehicle}
            displayType="full"
          />
        </Form.Item>
      </div>
      <div
        className={`${
          activeTab === DriverFormTab.CONFIG ? "flex" : "hidden"
        } flex-col gap-y-2 mt-2 mb-4`}
      >
        {flags.show_driver_start_location.enabled && (
          <>
            <div className="flex gap-x-3 mb-2 items-center">
              <Form.Item name="defaultRouteStartLocation" className="mb-0" valuePropName="checked">
                <Switch />
              </Form.Item>
              <div className="flex flex-col">
                <SemiBoldLabelText className="mb-0">
                  {t("profile.DriversSettings.useGeneralRouteSettingsForStartLocation")}
                </SemiBoldLabelText>
                {!withDefaultRouteStartLocation && (
                  <SecondaryText>{t("profile.DriversSettings.driverStartLocation")}:</SecondaryText>
                )}
              </div>
            </div>
            <div className={withDefaultRouteStartLocation ? "hidden" : ""}>
              <AddressAutocomplete
                name="routeStartAddress"
                label={t("profile.DriversSettings.startLocation")}
                placeholder={t("profile.DriversSettings.startLocation")}
                searchValue={routeStartAddress ?? ""}
                onSelected={onStartAddressSelected}
                onChange={(e: any) => {
                  form.setFieldValue("routeStartAddress", e.target.value)
                }}
                isInvalid={
                  withDefaultRouteStartLocation ? false : !isStartAddressValid || !routeStartAddress
                }
              />
              <Form.Item hidden name="routeStartLocation" />
            </div>
          </>
        )}
        <div className="flex gap-x-3 mb-2 items-center">
          <Form.Item name="defaultRouteEndLocation" className="mb-0" valuePropName="checked">
            <Switch />
          </Form.Item>
          <div className="flex flex-col">
            <SemiBoldLabelText className="mb-0">
              {t("profile.DriversSettings.useGeneralRouteSettingsForEndLocation")}
            </SemiBoldLabelText>
            {!withDefaultRouteEndLocation && (
              <SecondaryText>{t("profile.DriversSettings.driverEndLocation")}:</SecondaryText>
            )}
          </div>
        </div>
        <div className={withDefaultRouteEndLocation ? "hidden" : ""}>
          <AddressAutocomplete
            name="routeEndAddress"
            label={t("profile.DriversSettings.endLocation")}
            placeholder={t("profile.DriversSettings.endLocation")}
            searchValue={routeEndAddress ?? ""}
            onSelected={onEndAddressSelected}
            onChange={(e: any) => {
              form.setFieldValue("routeEndAddress", e.target.value)
            }}
            isInvalid={withDefaultRouteEndLocation ? false : !isEndAddressValid || !routeEndAddress}
          />
          <Form.Item hidden name="routeEndLocation" />
        </div>

        <div className="flex gap-x-3 mb-2 items-center">
          <Form.Item name="defaultMaxBatchSize" className="mb-0" valuePropName="checked">
            <Switch />
          </Form.Item>
          <div className="flex flex-col">
            <SemiBoldLabelText className="mb-0">
              {t("profile.DriversSettings.useGeneralRouteSettingsForMaxBatchSize")}
            </SemiBoldLabelText>
            {!withDefaultMaxBatchSize && (
              <SecondaryText>{t("profile.RouteSettings.maximumStopsPerRoute")}:</SecondaryText>
            )}
          </div>
        </div>
        <div className={withDefaultMaxBatchSize ? "hidden" : ""}>
          <Form.Item name="maxBatchSize" validateTrigger="onSubmit">
            <FloatInput
              name="maxBatchSize"
              placeholder={optimizationConfig.maxBatchSize.toString()}
              type="number"
              classname="rounded-md border-[#d9d9d9] !max-w-[120px]"
              size="small"
            />
          </Form.Item>
        </div>
        <div className="flex gap-x-3 mb-2 items-center">
          <Form.Item name="defaultMaxDistance" className="mb-0" valuePropName="checked">
            <Switch />
          </Form.Item>
          <div className="flex flex-col">
            <SemiBoldLabelText className="mb-0">
              {t("profile.DriversSettings.useGeneralRouteSettingsForMaxDistance")}
            </SemiBoldLabelText>
            {!withDefaultMaxDistance && (
              <SecondaryText>{t("profile.RouteSettings.maximumDistance")}:</SecondaryText>
            )}
          </div>
        </div>
        <div className={withDefaultMaxDistance ? "hidden" : ""}>
          <Form.Item name="maxDistanceInKm">
            <Input
              name="maxDistanceInKm"
              className="rounded-md border-[#d9d9d9] !max-w-[120px]"
              min={0}
              step={0.01}
              suffix={t("common.kilometresAbbreviation")}
              type="number"
              placeholder={((optimizationConfig.maxDistanceInMeters ?? 0) / 1000).toString()}
            />
          </Form.Item>
        </div>
        <div className="flex gap-x-3 mb-2 items-center">
          <Form.Item name="defaultMaxShiftTime" className="mb-0" valuePropName="checked">
            <Switch />
          </Form.Item>
          <div className="flex flex-col">
            <SemiBoldLabelText className="mb-0">
              {t("profile.DriversSettings.useGeneralRouteSettingsForMaxShiftTime")}
            </SemiBoldLabelText>
            {!withDefaultMaxShiftTime && (
              <SecondaryText>{t("profile.RouteSettings.maxShiftTime")}:</SecondaryText>
            )}
          </div>
        </div>
        <div className={`flex gap-x-2 ${withDefaultMaxShiftTime ? "hidden" : ""}`}>
          <Form.Item name={["maxShiftTime", "hours"]}>
            <Input
              className="!max-w-[80px]"
              suffix={t("common.hoursAbbreviation")}
              min={0}
              style={{ borderRadius: "none !important" }}
              type="number"
            />
          </Form.Item>
          <Form.Item name={["maxShiftTime", "minutes"]}>
            <Input
              className="!max-w-[100px]"
              suffix={t("common.minutesAbbreviation")}
              min={0}
              max={59}
              style={{ borderRadius: "none !important" }}
              type="number"
            />
          </Form.Item>
        </div>
      </div>
      <div className="flex w-full justify-end space-x-4">
        <KosmoButton type="default" onClick={cancelCallback}>
          {t("common.cancel")}
        </KosmoButton>
        <KosmoButton
          type="primary"
          htmlType="submit"
          isDisabled={(!isModifying || isLoading) && (attachments || [])?.length === 0}
        >
          {formForAdd ? t("common.add") : t("common.save")}
        </KosmoButton>
      </div>
    </Form>
  )
}
