import { ErrorKeys } from "@/types/errors.types"

export const errorsMap: {
  [key in ErrorKeys]: {
    description: string
    message: string
    skip?: boolean
    translationKey?: string
  }
} = {
  access_token_not_found: {
    description: "Unauthorized",
    message: "Access token could not be retrieved in the request.",
    skip: true,
  },
  duplicate_org: {
    description: "Duplicate organization",
    message: "Phone already linked to an existing organization in this country",
    skip: true,
  },
  internal_server_error: {
    description: "Something went wrong!",
    message: "Our server encountered an unexpected error, please contact our team.",
    skip: true,
  },
  confirm_payment_failed: {
    description: "Payment Failed",
    message: "Payment could not be processed, please verify your payment method in your settings.",
    translationKey: "errors.access_token_not_found",
  },
  invalid_access_token: {
    description: "Invalid token",
    message: "Access token is not valid",
    skip: true,
  },
  invitation_link_failed: {
    description: "Invitation failed",
    message:
      "There was a problem when inviting people, please try again. If the problem persists please contact our team",
    translationKey: "errors.invitation_failed",
  },
  failed_remove_user_org: {
    description: "There was a problem removing user from organization",
    message: "There was a problem removing user from organization",
    translationKey: "errors.failed_remove_user_org",
  },
  invalid_phone_number: {
    description: "Something went wrong!",
    message: "Provided phone is invalid, please make sure to use the right format",
    translationKey: "errors.invalid_phone_number",
  },
  error_partner_call: {
    description: "Something went wrong!",
    message: "An unexpected error, please contact our team",
    translationKey: "errors.error_partner_call",
  },
  route_optimization_quota: {
    description: "Quota exceeded",
    message: "You have exceeded your quota, please upgrade your plan or contact our team",
    translationKey: "errors.route_optimization_quota",
  },
  forbidden_access: {
    description: "Forbidden access",
    message: "You do not have access to the requested resource",
    skip: true,
  },
  invalid_webhook_url: {
    description: "Invalid webhook url",
    message: "Webhook url is not valid",
    skip: true,
  },
  delivery_invalid_state: {
    description: "Invalid status update",
    message: "New status not allowed",
    skip: true,
  },
  bad_request: {
    description: "Bad request",
    message: "The request was not valid",
    skip: true,
  },
  no_active_subscription: {
    description: "No active subscription",
    message: "No active subscription",
    skip: true,
  },
  failed_validate_credentials: {
    description: "Invalid credentials",
    message: "Credentials could not be verified",
    skip: true,
  },
  tips_cannot_be_more_than: {
    description: "Maximum tip amount",
    message: "You reached the tip limit amount",
    skip: true,
  },
  invalid_delivery_tips_status: {
    description: "Order can not be tipped",
    message: "Order can not be tipped in its current status",
    skip: true,
  },
  driver_has_uncompleted_orders: {
    description: "Driver has uncompleted orders",
    message: "Driver has uncompleted orders",
    translationKey: "errors.driver_has_uncompleted_orders",
  },
  already_exists: {
    description: "Already exists",
    message: "Order has already been duplicated",
    translationKey: "errors.already_exists",
    skip: true,
  },
  route_disconnected: {
    description: "Route disconnected",
    message: "Route disconnected",
    translationKey: "errors.route_disconnected",
    skip: true,
  },
  no_starting_location_for_routes: {
    description: "No starting location",
    message: "No starting location for routes",
    translationKey: "errors.no_starting_location_for_routes",
  },
}

export const defaultError = {
  description: "Internal Server Error",
  message: "Our server encountered an unexpected error, please contact our team.",
  translationKey: "errors.common",
  skip: true,
}
