export enum ZoneOptimizationConfig {
  DISABLED = '',
  STRICT = 'strict',
  PREFERRED = 'preferred',
}

export enum OptimizationMethod {
  DISTANCE = 'minimized',
  BALANCED = 'balanced',
}

export enum OptimizationBalanceBy {
  TIME = 'time',
  STOPS = 'stops',
  EFFICIENCY = 'efficiency',
}

export interface OptimizationConfig {
  optimizationMethod: OptimizationMethod
  balanceBy?: OptimizationBalanceBy
  enableMultiplePickups: boolean
  maxBatchSize: number
  maxDistanceInMeters?: number
  maxShiftTime?: number
  howLongToWaitAtLocation: number
  shouldEndAtPickupLocation: boolean
  shouldConsiderVolumetricCapacity: boolean
  delayWindow: number
  zones: ZoneOptimizationConfig
}

export const defaultOptimizationConfig = {
  optimizationMethod: OptimizationMethod.BALANCED,
  balanceBy: OptimizationBalanceBy.EFFICIENCY,
  enableMultiplePickups: false,
  maxBatchSize: 0,
  maxDistanceInMeters: undefined,
  maxShiftTime: undefined,
  howLongToWaitAtLocation: 0,
  shouldEndAtPickupLocation: false,
  shouldConsiderVolumetricCapacity: false,
  delayWindow: 0,
  zones: ZoneOptimizationConfig.DISABLED,
}
