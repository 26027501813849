import { IRoles } from '@/constants/role';
import { Team } from '@/types/teams.types';
import { ITeamMember } from '@/types/user.types';
import { atom, selector } from 'recoil';
import { driversSelector } from './driversAtom';
import { zonesAtom } from './zonesAtom';

export const teamsAtom = atom({
  key: "teamsAtom",
  default: [] as Team[],
})

export const orgUsersAtom = atom({
  key: "orgUsersAtom",
  default: [] as ITeamMember[]
})

export const managersSelector = selector({
  key: "managersSelector",
  get: ({ get }) => {
    return get(orgUsersAtom)?.filter((user) => user.role === IRoles?.MANAGER)
  }
})

export const enrichedTeamsSelector = selector({
  key: "enrichedTeamsSelector",
  get: ({ get }) => {
    const teams = get(teamsAtom)
    const availableDrivers = get(driversSelector)
    const availableManagers = get(managersSelector)
    const availableZones = get(zonesAtom)
    const enrichedTeams = teams?.map((team) => ({
      id: team?.id,
      name: team.name,
      managers: team?.managers
        ?.map((managerId) => availableManagers?.find((manager) => manager.id === managerId))
        ?.filter((manager) => manager),
      drivers: team?.drivers
        ?.map((driverId) => availableDrivers?.find((driver) => driver.id === driverId))
        ?.filter((driver) => driver),
      zones: team?.zones
        ?.map((zoneId) => availableZones?.find((zone) => zone.id === zoneId))
        ?.filter((zone) => zone),
    }))

    return enrichedTeams
  }
})
